<form class="wallet-transactions-filters" [formGroup]="transactionsHistoryFilterForm">
  <select
    formControlName="currency"
    class="wallet-transactions-filters__currency caption1--bold"
    (change)="refreshWalletTransactions()"
  >
    <option *ngFor="let currency of currenciesOptions" [value]="currency.englishName">
      {{ getCurrencyName(currency) }}
    </option>
  </select>
  <select
    formControlName="type"
    class="wallet-transactions-filters__type caption1--bold"
    (change)="refreshWalletTransactions()"
  >
    <option *ngFor="let type of transactionsTypes" [value]="type.value">
      {{ type.text | translate }}
    </option>
  </select>
  <div class="wallet-transactions-filters__date-picker-group">
    <mat-form-field appearance="outline" class="wallet-transactions-filters__date-picker">
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="fromDatePicker"
        class="wallet-transactions-filters__date-picker-input"
        [ngClass]="{
          error: !transactionsHistoryFilterForm.get('fromDate')?.valid || transactionsHistoryFilterForm?.errors?.fromDateShouldBeLessThanToDate,
        }"
        placeholder="{{ 'WALLET.TRANSACTION_HISTORY.START_DATE' | translate }}"
        formControlName="fromDate"
        (dateChange)="refreshWalletTransactions()"
        (keydown)="onDateFieldKeyUp($event)"
        autocomplete="off"
      />
      <mat-datepicker-toggle matPrefix [for]="fromDatePicker">
        <i matDatepickerToggleIcon class="icon icon-wallet-date-picker-icon"></i>
      </mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>
    <p class="wallet-transactions-filters__date-separator">-</p>
    <mat-form-field appearance="outline" class="wallet-transactions-filters__date-picker">
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="toDatePicker"
        class="wallet-transactions-filters__date-picker-input"
        [ngClass]="{
          error: !transactionsHistoryFilterForm.get('toDate')?.valid || transactionsHistoryFilterForm?.errors?.fromDateShouldBeLessThanToDate,
        }"
        placeholder="{{ 'WALLET.TRANSACTION_HISTORY.END_DATE' | translate }}"
        formControlName="toDate"
        (dateChange)="refreshWalletTransactions()"
        (keydown)="onDateFieldKeyUp($event)"
        autocomplete="off"
      />
      <mat-datepicker-toggle matPrefix [for]="toDatePicker">
        <i matDatepickerToggleIcon class="icon icon-wallet-date-picker-icon"></i>
      </mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <button
    class="wallet-transactions-filters__download-excel-button"
    type="button"
    (click)="onDownloadExcel()"
  >
    <i class="icon icon-excel-white"></i>
    <span class="caption1--medium">
      {{ 'WALLET.TRANSACTION_HISTORY.DOWNLOAD_EXCEL' | translate }}
    </span>
    <i class="icon icon-download"></i>
  </button>
</form>
